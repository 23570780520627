const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://devapi.leafquill.com/api';

export const fetchFromAPI = async (endpoint, options = {}) => {
  console.log(`${API_BASE_URL}${endpoint}`);
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    headers: {
     
      ...options.headers || {},
    },
    ...options,
  });
  if (!response.ok) {
    const errorText = await response.text(); // Read response as text for debugging
    console.error(`API error: ${response.status}`, errorText); // Log the status and response body
    throw new Error(`API error: ${response.status}`);
  }
  
  return response;
};
