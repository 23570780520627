import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ePub from 'epubjs';
import './BookReader.css';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://devapi.leafquill.com/api';

const BookReader = () => {
  const { bookId } = useParams(); // Get the book ID from the URL
  const viewerRef = useRef(null);
  const renditionRef = useRef(null);
  const [fontSize, setFontSize] = useState(16);

  // Initialize the book and rendition
  useEffect(() => {
    if (!bookId) {
      console.error('Book ID is required');
      return;
    }

    const viewerElement = viewerRef.current;

    if (!viewerElement) {
      console.error('Viewer element is not available');
      return;
    }

    const book = ePub(`${API_BASE_URL}/read/${bookId}.epub`);
    const rendition = book.renderTo(viewerElement, {
      width: '100%',
      height: '100%',
      manager: 'continuous',
      flow: 'paginated',
    });

    renditionRef.current = rendition;

    // Register custom theme
    rendition.themes.register('custom', {
      body: {
        lineHeight: '1.6',
      },
    });

    rendition.themes.select('custom'); // Apply the custom theme

    rendition.display()
      .then(() => {
        console.log('Book displayed successfully.');
      })
      .catch(err => console.error('Error displaying book:', err));
  }, [bookId]);

  // Handle font size changes
  useEffect(() => {
    if (renditionRef.current) {
      console.log(`Applying font size: ${fontSize}px`);
      // Apply font size without forcing a re-render
      renditionRef.current.themes.fontSize(`${fontSize}px`);
    }
  }, [fontSize]);

  const handleNext = () => renditionRef.current?.next();
  const handlePrevious = () => renditionRef.current?.prev();

  const increaseFontSize = () => {
    setFontSize((prev) => Math.min(prev + 2, 36)); // Increment by 2, max 36px
  };

  const decreaseFontSize = () => {
    setFontSize((prev) => Math.max(prev - 2, 12)); // Decrement by 2, min 12px
  };

  return (
    <div className="book-reader">
      <div className="book-reader__controls">
        <button onClick={decreaseFontSize}>A-</button>
        <span className="book-reader__label">Font Size</span>
        <button onClick={increaseFontSize}>A+</button>
      </div>
      <div
        className="book-reader__nav book-reader__nav--left"
        onClick={handlePrevious}
        aria-label="Previous Page"
      >
        <span className="book-reader__arrow"></span>
      </div>
      <div id="viewer" ref={viewerRef} className="book-reader__viewer"></div>
      <div
        className="book-reader__nav book-reader__nav--right"
        onClick={handleNext}
        aria-label="Next Page"
      >
        <span className="book-reader__arrow"></span>
      </div>
    </div>
  );
};

export default BookReader;
