import React, { useState } from 'react';
import { fetchFromAPI } from './api';
import './ResetPassword.css';

const ResetPassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const validatePasswords = () => {
    const errors = [];
    if (newPassword.length < 8) {
      errors.push('New password must be at least 8 characters long.');
    }
    if (!/\d/.test(newPassword)) {
      errors.push('New password must include at least one number.');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      errors.push('New password must include at least one special character.');
    }
    if (newPassword !== confirmNewPassword) {
      errors.push('New passwords do not match.');
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationErrors([]);
    setSuccessMessage('');

    const errors = validatePasswords();
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const response = await fetchFromAPI('/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ currentPassword, newPassword }),
      });

      if (response.ok) {
        setSuccessMessage('Password reset successfully!');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } else {
        const errorData = await response.json();
        setValidationErrors([errorData.message || 'Failed to reset password.']);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setValidationErrors(['An unexpected error occurred. Please try again.']);
    }
  };

  return (
    <div className="reset-password-page">
      <h2>Reset Password</h2>
      <form className="reset-password-form" onSubmit={handleSubmit}>
        {validationErrors.length > 0 && (
          <div className="error-messages">
            <ul>
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
