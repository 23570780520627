import React, { useState, useEffect } from 'react';
import { fetchFromAPI } from './api';
import './AuthorBooks.css';

const AuthorBooks = ({ authorId }) => {
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await fetchFromAPI(`/books?authorId=${authorId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch books');
        }
        const data = await response.json();
        setBooks(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchBooks();
  }, [authorId]);

  const handlePublish = async (bookId) => {
    try {
      const response = await fetchFromAPI(`/books/${bookId}/publish`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ authorId }),
      });

      if (!response.ok) {
        throw new Error('Failed to publish book');
      }

      setBooks((prevBooks) =>
        prevBooks.map((book) =>
          book.id === bookId ? { ...book, published: true } : book
        )
      );
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="author-books">
      <h1>Your Books</h1>
      {error && <p className="error">{error}</p>}
      <div className="books-list">
        {books.map((book) => (
          <div className="book-item" key={book.id}>
            <h2>{book.name}</h2>
            <p>{book.description}</p>
            {book.published ? (
              <button className="published" disabled>
                Published
              </button>
            ) : (
              <button onClick={() => handlePublish(book.id)}>Publish</button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AuthorBooks;
