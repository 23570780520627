// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useContext} from 'react';
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import BookReader from './components/BookReader';
import BookProduct from './components/BookProduct';
import Login from './components/Login';
import SignupForm from './components/SignupForm';
import { AuthProvider } from './context/AuthContext';
import Library from './components/Library';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';  
import AuthorDashboard from './components/AuthorDashboard';
import AuthorProfile from './components/AuthorProfile';
import PurchaseCreditsPage from './components/PurchaseCreditsPage';
import Checkout from './components/CheckoutPage';
import PaymentSuccessPage from './components/PaymentSuccessPage'; 
import MyAccount from './components/MyAccount';
import RouteLogger from './components/RouteLogger';
import PayoutPage from './components/PayoutDetails';
import CheckYourEmail from './components/CheckYourEmail';
import ResetPassword from './components/ResetPassword';
import VerificationPage from './components/VerificationPage';
import UploadBook from './components/UploadBook';
import HTMLEditor from './components/HTMLEditor';
import { MessageProvider } from './context/MessageContext';
import AuthorBooks from './components/AuthorBooks';
import AuthContext from './context/AuthContext'; // Import your AuthContext


function AppContent() {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const isBookReaderPage = location.pathname.startsWith('/reader/'); // Adjusted for /reader/

  return (
    <>
    <MessageProvider>
      <Header /> {/* Header is displayed on all pages */}
      <div className="main-content">
        <Routes>
        <Route path="/html-edit" element={<HTMLEditor />} />
          <Route path="/check-your-email" element={<CheckYourEmail />} />
          <Route path="/verify-email" element={<VerificationPage />} />
          <Route path="/" element={<Home />} />
          
          <Route path="/payout-details" element={<PayoutPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          <Route path="/purchase-credits" element={<PurchaseCreditsPage />} />
          <Route
            path="/reset-password"
            element={ 
              <ProtectedRoute>
                <ResetPassword />
              </ProtectedRoute>
            }
          />
          <Route
             path="/author/books"
             element={
              <ProtectedRoute>
              <AuthorBooks authorId={user?.id} /> 
              </ProtectedRoute>
            }
          />
          <Route
            path="/reader/:bookId"
            element={
              <ProtectedRoute>
                <BookReader />
              </ProtectedRoute>
            }
          />
           <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
           <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <AuthorProfile />
              </ProtectedRoute>
            }
          />

          <Route
            path="/author-dashboard"
            element={ 
              <ProtectedRoute>
                <AuthorDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-book"
            element={ 
              <ProtectedRoute>
                <UploadBook />
              </ProtectedRoute>
            }
          />
          <Route path="/books/:bookId" element={<BookProduct />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignupForm />} />
          
          <Route
            path="/library"
            element={
              <ProtectedRoute>
                <Library />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      {!isBookReaderPage && <Footer />} {/* Footer is conditionally rendered */}
      </MessageProvider>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <RouteLogger />
          <AppContent />
      </AuthProvider>
    </BrowserRouter>
  );
}


export default App;
