import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Elements, useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './PayoutDetails.css';
import { fetchFromAPI } from './api';
import { useMessage } from '../context/MessageContext.js';

const stripePromise = loadStripe('pk_test_51QNbQFB3kskbD2Vepm6Ja7rmwzG6p1lfR1Bk6us7ilbavJcnmhgAOVlI5nJ3SX6gX4dX9rH8iAI3EXpZab4QdeOE001p7rGs8I');

const BankDetailsForm = () => {
  const { user } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const { setMessage } = useMessage(); // Correctly use setMessage
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBankInfoVisible, setIsBankInfoVisible] = useState(false);
  const [payoutFrequency, setPayoutFrequency] = useState('weekly');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [country, setCountry] = useState('DE');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const toggleBankInfo = () => setIsBankInfoVisible(!isBankInfoVisible);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    
    if (!stripe || !elements) {
      setMessage({ text: 'Stripe.js has not loaded yet.', type: 'error' });
      setIsProcessing(false);
      return;
    }

    try {
      let result;
      if (country === 'US') {
        result = await stripe.createToken('bank_account', {
          country,
          currency: 'usd',
          routing_number: routingNumber,
          account_number: accountNumber,
          account_holder_name: accountHolderName,
          account_holder_type: 'individual',
        });
      } else {
        result = await stripe.createToken(elements.getElement(IbanElement), {
          account_holder_name: accountHolderName,
          account_holder_type: 'individual',
        });
      }

      if (result.error) {
        console.log(result.error.message);
        setMessage({ text: 'error', type: 'error' });
      } else {
        const response = await fetchFromAPI('/save-bank-details', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: user.id, token: result.token.id, frequency: payoutFrequency }),
        });

        if (!response.ok) {
          throw new Error('Failed to save bank details');
        }
        setMessage({ text: 'Bank details and payout preferences saved successfully!', type: 'success' });
      }
    } catch (error) {
      console.log(error.message);
      setMessage({ text: 'Error found.', type: 'error' });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payout-details-form">
      <h2>Payout Details</h2>

      <div className="form-group">
        <label>Payout Frequency</label>
        <select
          value={payoutFrequency}
          onChange={(e) => setPayoutFrequency(e.target.value)}
          required
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <div className="collapsible-section">
        <div className="collapsible-header" onClick={toggleBankInfo}>
          <span>Bank Information</span>
          <span className={`arrow ${isBankInfoVisible ? 'down' : ''}`}>&#x25B6;</span>
        </div>
        {isBankInfoVisible && (
          <div className="collapsible-content">
            <div className="form-group">
              <label>Account Holder Name</label>
              <input
                type="text"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                placeholder="John Doe"
                required
              />
            </div>
            <div className="form-group">
              <label>Country</label>
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
              >
                <option value="DE">Germany</option>
                <option value="FR">France</option>
                <option value="ES">Spain</option>
                <option value="US">United States</option>
              </select>
            </div>
            {country === 'US' ? (
              <>
                <div className="form-group">
                  <label>Routing Number</label>
                  <input
                    type="text"
                    value={routingNumber}
                    onChange={(e) => setRoutingNumber(e.target.value)}
                    placeholder="123456789"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Account Number</label>
                  <input
                    type="text"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    placeholder="123456789123"
                    required
                  />
                </div>
              </>
            ) : (
              <div className="form-group">
                <label>IBAN</label>
                <IbanElement
                  options={{
                    supportedCountries: ['SEPA'],
                    placeholderCountry: country,
                    style: {
                      base: { fontSize: '16px', color: '#424770' },
                      invalid: { color: '#9e2146' },
                    },
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <button type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? 'Processing...' : 'Save Payout Details'}
      </button>
    </form>
  );
};

const BankDetailsPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <BankDetailsForm />
    </Elements>
  );
};

export default BankDetailsPage;
