import { createContext, useContext, useState } from 'react';

// Create the context
const MessageContext = createContext();

// Message Provider
export const MessageProvider = ({ children }) => {
  // eslint-disable-next-line  
  const [message, setMessage] = useState({ text: '', type: '' }); // Correctly manage state

  return (
    <MessageContext.Provider value={{message, setMessage}}>
      {children}
    </MessageContext.Provider>
  );
};

// Hook to use the context
export const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context; // Returns the setMessage function
};
