import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false); // Tracks author status
  const [isAdmin, setIsadmin] = useState(false);
  const [totalCredits, setCredits] = useState(0); // Total credits
  const [totalCost, setTotalCost] = useState(0); // Total cost
  const [userCredits, setUserCredits] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize user and isAuthor status from localStorage
    const loggedInUser = localStorage.getItem('user');
    const authorStatus = localStorage.getItem('isAuthor') === 'true';
    const adminStatus = localStorage.getItem('isAdmin') === 'true';
    const credits = localStorage.getItem("credits");
    if (loggedInUser) {
      setUser(JSON.parse(loggedInUser));
      setIsAuthor(authorStatus);
      setIsadmin(adminStatus);
      setUserCredits(credits);
    }
  }, []);

  const login = (userData, authorStatus, adminStatus, credits) => {
    setUser(userData);
    setIsAuthor(authorStatus);
    setIsadmin(adminStatus);
    setUserCredits(credits);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('isAuthor', authorStatus);
    localStorage.setItem('isAdmin', adminStatus);
    localStorage.setItem('userCredits', credits);

    navigate('/dashboard');
  };

  const logout = () => {
    setUser(null);
    setIsAuthor(false);
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthor');
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("userCredits");
    navigate('/login');
  };

  const checkoutCredits = (creditAmount, cost) => {
    setTotalCost(cost);
    setCredits(creditAmount);
  };

  // Utility to check if the user is an author
  const isUserAuthor = () => isAuthor;
  const isUserAdmin = () => isAdmin;

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthor,
        login,
        logout,
        totalCredits,
        totalCost,
        checkoutCredits,
        isAdmin,
        userCredits,
        isUserAdmin,
        isUserAuthor, // Optional utility function
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
