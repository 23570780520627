import React, { useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
//import { fetchFromAPI } from './api';
import './MyAccount.css';
import AuthContext from '../context/AuthContext'; // Import your AuthContext


const AccountPage = () => {
  const { isAuthor} = useContext(AuthContext);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
     
    };

    fetchUserData();
  }, []);

 

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="account-page">
      <h2>Welcome!</h2>
      <div className="account-buttons">
        <button onClick={() => handleNavigation('/reset-password')}>Reset Password</button>
        <button onClick={() => handleNavigation('/view-orders')}>View Orders</button>
        {isAuthor ? (
          <>
            <button onClick={() => handleNavigation('/profile')}>Update Profile</button>
            <button onClick={() => handleNavigation('/upload-book')}>Upload Book</button>
            <button onClick={() => handleNavigation('/author/books')}>My Books</button>
            <button onClick={() => handleNavigation('/payout-details')}>Payout Settings</button>
          </>
        ) : (
          <button onClick={() => handleNavigation('/profile')}>Become an Author</button>
        )}
       
      </div>
    </div>
  );
};

export default AccountPage;
