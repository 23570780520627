import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignupForm.css';
import { fetchFromAPI } from './api';

const SignupForm = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    specialChar: false,
  });
  const [errorMessage, setErrorMessage] = useState(''); // For inline error message
  const navigate = useNavigate();

  const validatePassword = (value) => {
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const isValidLength = value.length >= 8;

    setPasswordValidations({
      length: isValidLength,
      number: hasNumber,
      specialChar: hasSpecialChar,
    });

    setPassword(value);
  };

  const handleSubmit = async (e) => {
    console.log("Starting submit.");
    e.preventDefault();

    if (
      !username ||
      !email ||
      !passwordValidations.length ||
      !passwordValidations.number ||
      !passwordValidations.specialChar ||
      password !== confirmPassword
    ) {
      setErrorMessage('Please ensure all fields are valid before submitting!');
      return;
    }
    console.log("Sending data...");
    const endpoint = '/signup';
    try {
      const response = await fetchFromAPI(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, email, password }),
      });

      if (response.ok) {
        navigate('/check-your-email');
      } else {
        setErrorMessage('Signup failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while signing up. Please try again.');
    }
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2 className="signup-header">Sign Up</h2>
        {errorMessage && <div className="signup-error" role="alert">{errorMessage}</div>}
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => validatePassword(e.target.value)}
          required
        />
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          id="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <div className="password-validations">
          <p>Password must:</p>
          <ul>
            <li className={passwordValidations.length ? 'valid' : 'invalid'}>
              Be at least 8 characters
            </li>
            <li className={passwordValidations.number ? 'valid' : 'invalid'}>
              Include a number
            </li>
            <li
              className={passwordValidations.specialChar ? 'valid' : 'invalid'}
            >
              Include a special character
            </li>
          </ul>
        </div>
        <button type="submit" className="signup-button">
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignupForm;
