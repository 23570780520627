// src/components/BookProduct.js
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import './BookProduct.css';
import { fetchFromAPI } from './api';

// create class
const BookProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { bookId } = useParams();
  const [book, setBook] = useState(location.state || null);
  

  useEffect(() => {

    if (!book) {
      fetchFromAPI(`/books/${bookId}`)
        .then((response) => response.json())
        .then((data) => setBook(data))
        .catch((error) => console.error('Error fetching book details:', error));
    }
  }, [book, bookId]);

  if (!book) return <p>Loading...</p>;

  const { title, description, price, cover_image_url, cover_image_alt, first_name, last_name } = book;
  

  const handlePurchase = async () => {
    if (!user) {
      navigate('/login');
      return;
    }
    console.log('User data:', user); // Log user data to check if id is available
    console.log("Book: " + book.id);
    console.log("Price: " + book.price);
    try {
      const response = await fetchFromAPI('/purchase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          bookId: book.id,
          purchasePrice: book.price
        }),
      });

      if (response.ok) {
        alert('Purchase successful!');
      } else {
        const data = await response.json();
        alert(`Purchase failed: ${data.message}`);
      }
    } catch (error) {
      console.error('Error during purchase:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  return (
    <div className="book-product">
      <div className="book-product-image">
        <img src={cover_image_url || '/default-cover.jpg'} alt={cover_image_alt || `${title} cover`} />
      </div>
      <div className="book-product-details">
        <h2>{title}</h2>
        <h3>By {first_name} {last_name}</h3>
        <p className="book-description">{description}</p>
        <p className="book-price">
          {price !== undefined && !isNaN(price) ? `Credits: {price}` : 'Price not available'}
        </p>

        {user ? (
          <button className="purchase-button" onClick={handlePurchase}>
            Purchase
          </button>
        ) : (
          <button className="login-to-purchase" onClick={handleLoginRedirect}>
            Login to Purchase
          </button>
        )}
      </div>
    </div>
  );
};

export default BookProduct;
