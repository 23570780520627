// src/components/BookTile.js
import React from 'react';
import './BookTile.css';

const BookTile = ({ book = {}, hidePrice = false, onClick = () => {} }) => {
  const { id, name, author, cover_image_url, cover_image_alt, price, first_name, last_name } = book;
  //console.log('Rendering BookTile with:', book); // Log book data
  console.log("Author: " + JSON.stringify(author));

  return (
    <div className="book-tile" key={id} onClick={onClick}>
      <img src={cover_image_url || '/default-cover.jpg'} alt={cover_image_alt || 'Book cover'} className="book-tile-image" />
      <div className="book-tile-info">
        <h3>{name}</h3>
        <p>{first_name} {last_name}</p>
        {!hidePrice && price !== undefined && !isNaN(price) ? (
          <p className="book-price">Credits: {Math.floor(price)}</p>
        ) : null}
      </div>
    </div>
  );
};

export default BookTile;
