import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { useMessage } from '../context/MessageContext'; // Import useMessage
import './Header.css';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // Global message state from MessageContext
  const { message, setMessage } = useMessage();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.dropdown-menu') &&
        !event.target.closest('.account-name')
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Clear the message banner
  const clearMessage = () => setMessage({ text: '', type: '' });

  return (
    <header className="header">
      {message.text && (
        <div className={`message-banner ${message.type}`}>
          <span>{message.text}</span>
          <button className="message-close" onClick={clearMessage}>
            &times;
          </button>
        </div>
      )}
      <div className="header-content">
        <div className="header-branding">
          <Link to="/" className="header-logo">
            <h1>LeafQuill</h1>
          </Link>
        </div>

        {user ? (
          <div className="header-actions">
            <div className="account-name" onClick={toggleMenu}>
              {user.username || 'Account'}
            </div>
            <div className={`dropdown-menu ${menuOpen ? 'show' : ''}`}>
              <span className="dropdown-item">
                Credits: {user.userCredits || 0}
              </span>
              <span
                className="dropdown-item"
                onClick={() => navigate('/library')}
              >
                View Library
              </span>
              <span
                className="dropdown-item"
                onClick={() => navigate('/purchase-credits')}
              >
                Purchase Credits
              </span>
              <span
                className="dropdown-item"
                onClick={() => navigate('/my-account')}
              >
                My Account
              </span>
              <span className="dropdown-item" onClick={logout}>
                Log Out
              </span>
            </div>
          </div>
        ) : (
          <div className="header-actions">
            <Link to="/signup" className="header-button">
              Sign Up
            </Link>
            <Link to="/login" className="header-button">
              Login
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
